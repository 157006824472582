import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/error_pages.scss"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <section className="message msg-error">

      <h1>Uh oh.</h1>
      <h2>The page you're trying to access doesn&#39;t exist... the sadness.</h2>

      <p>Instead, visit our <Link to="/">homepage</Link> for the latest.</p>

    </section>
  </Layout>
)

export default NotFoundPage
